import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class HeaderMenu extends Component {
	render() {
		return (
			<>
				<ul className="nav navbar-nav">
					<li className="active">
						<Link to={'/index-7'}>
							<span className="ti-home"></span> <i className=""></i>
						</Link>
					</li>
					<li>
						<Link to={'/error-404'}>
							Services client <i className=""></i>
						</Link>
					</li>
					<li>
						<Link to={'/error-404'}>
							Forfaits Ninja <i className=""></i>
						</Link>
					</li>
					<li>
						<Link to={'/error-404'}>
							Équipe de la maison <i className=""></i>
						</Link>
					</li>
					<li>
						<Link to={'/error-404'}>
							Demande de service<i className=""></i>
						</Link>
					</li>
					<li>
						<a
							href="https://laborninja-dashboard.mspti.dev"
							target="_blank"
							rel="noreferrer"
						>
							Tableau de bord Ninja <i className=""></i>{' '}
						</a>
					</li>
				</ul>
			</>
		)
	}
}
export default HeaderMenu
