import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Footer6 extends Component {
	render() {
		return (
			<>
				<footer className="site-footer">
					<div className="footer-top bg-gray-dark">
						<div className="container">
							<div className="row">
								<div className="col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4">
									<div className="widget widget_services border-0">
										<h5 className="m-b30 text-white">Pages pratiques</h5>
										<ul>
											<li>
												<Link to={'#'}>Accueil </Link>
											</li>
											<li>
												<Link to={'#'}>Nos services</Link>
											</li>
											<li>
												<Link to={'#'}>À propos</Link>
											</li>
											<li>
												<Link to={'#'}>Contact</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4">
									<div className="widget widget_services border-0">
										<h5 className="m-b30 text-white">Plus d'information</h5>
										<ul>
											<li>
												<a href="mailto:info@laborninja.ca">Nous joindre</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 footer-col-4">
									<div className="widget widget_getintuch">
										<h5 className="m-b30 text-white">Contact</h5>
										<ul>
											<li>
												<i className="ti-location-pin"></i>
												<strong>adresse</strong>Laval
											</li>
											<li>
												<i className="ti-mobile"></i>
												<strong>téléphone</strong>(514) 553-7732 <br />
												(Lun à Ven 9h à 17h)
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 ">
									<div className="widget">
										<h5 className="m-b30 text-white">
											Prêt à suivre la voie du ninja.
										</h5>
										<p className="text-capitalize m-b20">
											Pour trouver un Ninja ou trouver de la nouveau client
											contacter nous ici par messagerie ou dans notre section
											contact.
										</p>
										<Link
											to={'#'}
											className="site-button radius-xl text-white m-b5 m-r10"
										>
											Messagerie
										</Link>
										<Link to={'#'} className="site-button radius-xl m-b5 white">
											Page de contact
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</>
		)
	}
}

export default Footer6
