import React, { Component } from 'react'

export default class ThemeButton extends Component {
	render() {
		return (
			<>
				<a href="" className="bt-buy-now theme-btn">
					<i className="ti-shopping-cart"></i>
					<span>Devenir Ninja</span>
				</a>
			</>
		)
	}
}
