import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header1 from './../Layout/Header1'
import Footer6 from './../Layout/footer6'
import Index7Slider from './../Element/Index7Slider'
import Index7ClientCarousel from './../Element/Index7ClientCarousel'

import icon4 from './../../images/icons/demande-service.svg'
import icon5 from './../../images/icons/trouver-main-doeuvre.svg'
import icon6 from './../../images/icons/travaux.svg'

import test1 from './../../images/testimonials/pic1.jpg'
import test2 from './../../images/testimonials/pic2.jpg'
import test3 from './../../images/testimonials/pic3.jpg'

import recent5 from './../../images/employees/Jimmy Boily.png'
import recent6 from './../../images/employees/Joel Daoust.png'
import recent7 from './../../images/employees/Kevin Jalbert.png'
import recent8 from './../../images/employees/Sarah Thellen.png'
import recent9 from './../../images/employees/Pascal Lavigne.png'

import bg22 from './../../images/background/bg22.png'
import bg23 from './../../images/background/bg23.png'

const wrpaerBlog = [
	{ image: icon4, title: 'Demande de service' },
	{ image: icon5, title: "Nous trouvons votre main-d'oeuvre qualifiée" },
	{ image: icon6, title: 'Execution des travaux' },
]

const wrpaerBlog2 = [
	{ image: icon4, title: 'Inscription' },
	{ image: icon5, title: 'Conception de votre dossier' },
	{ image: icon6, title: 'Travaillez selon vos dispo et votre taux horaire' },
]

const expertBox = [
	{ image: test1 },
	{ image: test2 },
	{ image: test3 },
	{ image: test2 },
	{ image: test3 },
	{ image: test1 },
]

const hoverBlog = [
	{ title: 'Shop', icon: <i className="flaticon-shop" /> },
	{ title: 'Star', icon: <i className="flaticon-star" /> },
	{ title: 'Play', icon: <i className="flaticon-play-button" /> },
	{ title: 'Heart', icon: <i className="flaticon-like" /> },
]

class Index7 extends Component {
	componentDidMount() {
		var i = 0

		// Placeholder Animation Start
		var inputSelector = document.querySelectorAll('input, textarea')

		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('focus', function (event) {
				return this.parentElement.parentElement.classList.add('focused')
			})
		}

		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('blur', function (event) {
				var inputValue = this.value
				if (inputValue === '') {
					this.parentElement.parentElement.classList.remove('filled')
					this.parentElement.parentElement.classList.remove('focused')
				} else {
					this.parentElement.parentElement.classList.add('filled')
				}
			})
		}
	}
	render() {
		return (
			<>
				<Header1 />

				<div className="page-content">
					<div className="owl-slider-banner main-slider dz-style-1">
						<Index7Slider />
					</div>

					{/* <!-- About Us --> */}
					<div className="section-full bg-white p-b30">
						<div className="container">
							<div className="row about-1">
								<div className="icon-bx-wraper col-md-4 bg-white col-lg-4 p-tb30 p-l30 p-r50">
									<div className="icon-md m-b20">
										<span className="icon-cell">
											<img src="images/icon/icon3.png" alt="" />
										</span>
									</div>
									<div className="icon-content">
										<h3 className="dez-tilte">
											<span className="font-weight-300">Bienvenue </span> Ninja
										</h3>
										<p>
											Labor Ninja est un concept basé sur les besoins actuels et
											très mal comblés ... celui de trouver des employés ou des
											travailleurs qualifiés dans votre secteur. Notre mission
											est de régler ce problème pour vous !
										</p>
									</div>
								</div>
								<div className="icon-bx-wraper bg-primary p-lr30 about-scale text-white col-md-4 col-lg-4 p-tb30">
									<div className="icon-md m-b20">
										<span className="icon-cell">
											<img src="images/icon/icon1.png" alt="" />
										</span>
									</div>
									<div className="icon-content">
										<h3 className="dez-tilte">
											<span className="font-weight-300">Un peu de </span>Tout
										</h3>
										<ul className="list-arrow-right m-b0">
											<li>
												<i className="fa fa-arrow-right"></i>Rénovation
											</li>
											<li>
												<i className="fa fa-arrow-right"></i>Peinture
											</li>
											<li>
												<i className="fa fa-arrow-right"></i>Entretien
											</li>
											<li>
												<i className="fa fa-arrow-right"></i>Travaux spécialisés
											</li>
											<li>
												<i className="fa fa-arrow-right"></i>Transports
											</li>
											<li>
												<i className="fa fa-arrow-right"></i>Aide en tout genre
											</li>
											<li>
												<i className="fa fa-arrow-right"></i>Tout ce qu'il vous
												faut rapidement
											</li>
										</ul>
									</div>
								</div>
								<div className="icon-bx-wraper p-l50 p-r30 col-md-4 bg-white col-lg-4 p-tb30">
									<div className="icon-md m-b20">
										<span className="icon-cell">
											<img src="images/icon/icon2.png" alt="" />
										</span>
									</div>
									<div className="icon-content">
										<h3 className="dez-tilte">
											<span className="font-weight-300">On se charge </span>De
											trouver
										</h3>
										<p>
											Notre équipe trouvera pour vous le meilleur choix de main
											d'oeuvre qualifiée dans votre région sans aucun frais de
											vorte part ! Nous offrons ce service autant pour les
											entreprises que les particuliers. Il suffit de nous
											demander les services que vous avez besoin et nous nous
											chargeons du reste !
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- About Us END --> */}
					{/* <!-- Our Services --> */}
					<div className="section-full content-inner-2 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 section-head text-center">
									<h5 className="title-small">Comment ça fonctionne</h5>
									<h2 className="title-head m-b0">
										Pour les clients qui cherchent de la main d'oeuvre à louer
									</h2>
								</div>
							</div>
							<div className="row">
								{wrpaerBlog.map((data, index) => (
									<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
										<div className="icon-bx-wraper our-service center">
											<div className="icon-lg text-primary m-b20">
												<Link to={'#'} className="icon-cell">
													<img src={data.image} alt="" />
												</Link>
											</div>
											<div className="icon-content p-lr50">
												<h5 className="dez-tilte text-uppercase">
													{data.title}
												</h5>
												<p></p>
											</div>
										</div>
									</div>
								))}
								<div className="col-lg-12 m-t20 text-center">
									<Link
										to={'#'}
										className="site-button long-btn radius-xl shadow"
									>
										Détails sur nos services
									</Link>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Our Services END --> */}
					{/* <!-- Our Services 2 2 2 2 2 2 2 2 2 2 2 --> */}
					<div className="section-full content-inner-2 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 section-head text-center">
									<h5 className="title-small">Comment ça fonctionne</h5>
									<h2 className="title-head m-b0">Pour les Ninjas</h2>
								</div>
							</div>
							<div className="row">
								{wrpaerBlog2.map((data, index) => (
									<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
										<div className="icon-bx-wraper our-service center">
											<div className="icon-lg text-primary m-b20">
												<Link to={'#'} className="icon-cell">
													<img src={data.image} alt="" />
												</Link>
											</div>
											<div className="icon-content p-lr50">
												<h5 className="dez-tilte text-uppercase">
													{data.title}
												</h5>
												<p></p>
											</div>
										</div>
									</div>
								))}
								<div className="col-lg-12 m-t20 text-center">
									<Link
										to={'#'}
										className="site-button long-btn radius-xl shadow"
									>
										Détails sur nos services
									</Link>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Our Services 2 END --> */}
					{/* <!-- Experts --> */}

					{/* <!-- Experts END -->	 */}
					{/* <!-- Pricing Table --> */}
					{/* <!-- Pricing Table END --> */}
					{/* <!-- Ticket KIt Section --> */}
					<div
						className="section-full content-inner bg-primary"
						style={{
							backgroundImage: 'url(' + bg22 + ')',
							backgroundPosition: 'center',
						}}
					>
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-5 m-b30">
									<div className="tickit-box bg-white text-center">
										<span className="title">Vous cherchez un professionel</span>
										<h2 className="price text-primary">
											<span>$</span>0
										</h2>
										<p>
											Il est temps de découvrir un bassin de professionels
											autour de nous et spécialement choisis pour vous.
										</p>
										<Link to={'#'} className="site-button btn-block radius-xl">
											Nous joindre
										</Link>
									</div>
								</div>
								<div className="col-lg-7 offset-lg-1 col-md-7 text-white m-b30">
									<div className="tickit-info">
										<h2 className="font-50">
											Labor Ninja trouveras
											<br /> Pour vous
										</h2>
										<p>
											“Labor Ninja est bien plus qu'une plateforme de services
											en ligne. Elle redéfinit la recherche de professionels
											pour combler vos besoins avec de véritables recherchistes
											qui travail pour vous à temps plein et cela complètement
											gratuitement.”
										</p>

										<span>-L'équipe de Labor Ninja</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Ticket KIt Section End --> */}
					{/* <!-- Our Services --> */}
					{/* <div className="section-full content-inner bg-white"> */}
					{/* 	<div className="container"> */}
					{/* 		<div className="section-head text-black text-center"> */}
					{/* 			<h2 className="title-head m-b0">Parlez à un Ninja</h2> */}
					{/* 		</div> */}
					{/* 		<div className="row"> */}
					{/* 			{hoverBlog.map((data, index) => ( */}
					{/* 				<div className="col-lg-3 col-md-6 col-sm-6"> */}
					{/* 					<div className="text-center  icon-hover-bx"> */}
					{/* 						<div className="icon-bx-xl text-primary bg-white radius border-2"> */}
					{/* 							<Link to={'#'} className="icon-cell icon-md"> */}
					{/* 								{data.icon} */}
					{/* 							</Link> */}
					{/* 						</div> */}
					{/* 						<div className="icon-content m-t30"> */}
					{/* 							<h5 className="dez-tilte text-uppercase"> */}
					{/* 								{data.title} */}
					{/* 							</h5> */}
					{/* 							<p>Fonction à terminer et remonter vers le haut</p> */}
					{/* 						</div> */}
					{/* 					</div> */}
					{/* 				</div> */}
					{/* 			))} */}
					{/* 		</div> */}
					{/* 	</div> */}
					{/* </div> */}
					{/* <!-- Contact Form --> */}
					<div
						className="section-full content-inner bg-primary contact-2"
						style={{ backgroundImage: 'url(' + bg23 + ')' }}
					>
						<div className="container">
							<div className="row d-flex align-items-center">
								<div className="col-lg-7">
									<div className="">
										<div className="support-form support-box-form bg-white">
											<div className="support-title m-b30">
												<h6 className="text-uppercase font-weight-500 m-b10">
													Nous joindre
												</h6>
												<h2 className="font-40 font-weight-400 m-tb0">
													Question ou service
												</h2>
											</div>
											<div className="dezPlaceAni">
												<div className="dzFormMsg"></div>
												<form method="post" className="" action="">
													<input type="hidden" value="Contact" name="dzToDo" />
													<div className="row">
														<div className="col-lg-6 col-md-6 col-sm-6 col-12">
															<div className="form-group">
																<div className="input-group">
																	<label>Votre nom</label>
																	<input
																		name="dzName"
																		type="text"
																		required=""
																		className="form-control"
																		placeholder=""
																	/>
																</div>
															</div>
														</div>
														<div className="col-lg-6 col-md-6 col-sm-6 col-12">
															<div className="form-group">
																<div className="input-group">
																	<label>No. Téléphone</label>
																	<input
																		name="dzOther[Phone]"
																		type="text"
																		required=""
																		className="form-control"
																		placeholder=""
																	/>
																</div>
															</div>
														</div>
														<div className="col-lg-12 col-md-12 col-sm-12 col-12">
															<div className="form-group">
																<div className="input-group">
																	<label>Votre adresse courriel</label>
																	<input
																		name="dzEmail"
																		type="email"
																		className="form-control"
																		required=""
																		placeholder=""
																	/>
																</div>
															</div>
														</div>
														<div className="col-lg-12 col-md-12 col-sm-12 col-12">
															<div className="form-group">
																<div className="input-group">
																	<label>Votre message...</label>
																	<textarea
																		name="dzMessage"
																		rows="4"
																		className="form-control"
																		required=""
																		placeholder=""
																	></textarea>
																</div>
															</div>
														</div>
														<div className="col-lg-12 col-md-12 col-sm-12">
															<button
																name="submit"
																type="submit"
																value="Submit"
																className="site-button radius-xl button-md m-t10"
															>
																Envoyer
															</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-5">
									<div className="contact-info-bx p-a30 text-white">
										<div className="m-b10">
											<span>Addresse</span>
											<h3>Laval</h3>
										</div>
										<div className="m-b10">
											<span>Par courriel</span>
											<h3>Info@laborninja.ca</h3>
										</div>
										<div className="m-b10">
											<span>Par téléphone</span>
											<h3>(514) 553-7732</h3>
										</div>
										<div className="m-b10">
											<span>Suivez la voix du ninja</span>
											<ul className="list-inline socail-icon m-t15 m-a0">
												<li>
													<Link to={'#'} className="site-button white facebook">
														<i className="fa fa-facebook"></i>
													</Link>
												</li>
												<li>
													<Link to={'#'} className="site-button white linkedin">
														<i className="fa fa-linkedin"></i>
													</Link>
												</li>
												<li>
													<Link
														to={'#'}
														className="site-button white instagram"
													>
														<i className="fa fa-instagram"></i>
													</Link>
												</li>
												<li>
													<Link to={'#'} className="site-button white twitter">
														<i className="fa fa-twitter"></i>
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Our Services End --> */}
					<div className="section-full content-inner our-stories bg-white">
						<div className="container">
							<div className="section-head text-black text-center">
								<h2 className="title-head m-b0">
									Notre équipe de Ninjas maison
								</h2>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<div className="row">
										<div className="col-lg-4 col-md-6 col-sm-6">
											<div className="blog-post blog-lg">
												<div className="dez-post-media dez-img-effect radius-sm">
													<Link to={'#'}>
														<img src={recent9} alt="" />
													</Link>
												</div>
												<div className="dez-info">
													<div className="dez-post-title">
														<h4 className="post-title">
															<Link to={'#'}>Pascal Lavigne</Link>
														</h4>
													</div>
													<div className="dez-post-tags">
														<span>
															Pascal est notre gérant des employés. Il dirrige
															un équipe comme nulle autre ne sait le faire. Il a
															beaucoup d'exprérience dans la gestion d'employés,
															la mécanique, le transport, la rénovation,
															l'émondage et plusieurs autres domaines. Vous
															pouvez aussi lui faire remplacer des postes vacans
															de votre entreprise.
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-4 col-md-6 col-sm-6">
											<div className="blog-post blog-lg">
												<div className="dez-post-media dez-img-effect radius-sm">
													<Link to={'#'}>
														<img src={recent5} alt="" />
													</Link>
												</div>
												<div className="dez-info">
													<div className="dez-post-title">
														<h4 className="post-title">
															<Link to={'#'}>Jimmy Boily</Link>
														</h4>
													</div>
													<div className="dez-post-tags">
														<span>
															Jimmy à plusieurs années d'expérience en
															mécanique. Il peut faire vos entretiens à la
															maison comme à votre commerce, tous vos transports
															ou remplacer votre manque d'employés temporaire.
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-4 col-md-6 col-sm-6">
											<div className="blog-post blog-lg">
												<div className="dez-post-media dez-img-effect radius-sm">
													<Link to={'#'}>
														<img src={recent6} alt="" />
													</Link>
												</div>
												<div className="dez-info">
													<div className="dez-post-title">
														<h4 className="post-title">
															<Link to={'#'}>Joel D'Aoust</Link>
														</h4>
													</div>
													<div className="dez-post-tags">
														<span>
															Joel est ponctuel et toujours au poste. Choissisez
															le pour vos travaux d'entretien extérieurs, vos
															travaux de peinture, de construction ou pour
															remplacer temporairement des employés de votre
															entreprise.
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-4 col-md-6 col-sm-6">
											<div className="blog-post blog-lg">
												<div className="dez-post-media dez-img-effect radius-sm">
													<Link to={'#'}>
														<img src={recent7} alt="" />
													</Link>
												</div>
												<div className="dez-info">
													<div className="dez-post-title">
														<h4 className="post-title">
															<Link to={'#'}>Kevin Jalbert</Link>
														</h4>
													</div>
												</div>
												<div className="dez-post-tags">
													<span>
														Louez Kevin pour tous vos travaux d' entretien
														extérieurs, ménages après construction ou pour
														remplacer des employés dans votre entreprise. Kevin
														est gentil, souriant et toujours présent.
													</span>
												</div>
											</div>
										</div>
										<div className="col-lg-4 col-md-6 col-sm-6">
											<div className="blog-post blog-lg">
												<div className="dez-post-media dez-img-effect radius-sm">
													<Link to={'#'}>
														<img src={recent8} alt="" />
													</Link>
												</div>
												<div className="dez-info">
													<div className="dez-post-title">
														<h4 className="post-title">
															<Link to={'#'}>Sarah Thellen</Link>
														</h4>
													</div>
													<div className="dez-post-tags">
														<span>
															Sarah est une entrepreneure en entretien ménager.
															Elle peut prendre vos contrats de ménages
															résidentiels, commerciaux ou après construction.
															Extrêmement minutieuse et rapide, elle saura vous
															épater.
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Our Stories Blog End --> */}
					{/* <!-- Contact Form END --> */}
					<div className="section-full bg-primary-dark p-tb15">
						<div className="container">
							<Index7ClientCarousel />
						</div>
					</div>
					{/* <!-- Partners Logo --> */}
				</div>

				<Footer6 />
			</>
		)
	}
}
export default Index7
