import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from './../../Layout/Header1'
import Footer from './../../Layout/Footer1'
import PageTitle from './../../Layout/PageTitle'
import SectionCounter from './../../Element/SectionCounter'
import TestimonialCarousel from './../../Element/TestimonialCarousel'
import FormStyle from './../../Element/FormStyle'

//Images
import bnr5 from './../../../images/banner/bnr5.jpg'
import bgmap from './../../../images/background/bg-map.jpg'
import bg1 from './../../../images/background/bg1.jpg'

const iconBox = [
	{ icon: <i className="flaticon-devices" />, title: 'Web Design Solutions' },
	{
		icon: <i className="flaticon-pen" />,
		title: 'Web Development  Solutions ',
	},
	{ icon: <i className="flaticon-bar-chart" />, title: 'SEO/SMO Solutions' },
	{ icon: <i className="flaticon-file" />, title: 'Pre Customized Solutions' },
	{ icon: <i className="flaticon-notebook" />, title: 'Project Maintenance' },
	{ icon: <i className="flaticon-team" />, title: 'Social Networking Portal' },
]

class Service extends Component {
	render() {
		return (
			<>
				<Header />

				<div className="page-content bg-white">
					{/* <!-- inner page banner --> */}
					<div
						className="dlab-bnr-inr overlay-primary"
						style={{ backgroundImage: 'url(' + bnr5 + ')' }}
					>
						<PageTitle motherMenu="Our Services" activeMenu="Our Services" />
					</div>
					{/* <!-- inner page banner END --> */}
					<div className="content-block">
						{/* <!-- About Us --> */}
						<div className="section-full content-inner">
							<div className="container">
								<div className="section-head text-black text-center">
									<h4 className="text-gray-dark m-b10">Nos services</h4>
									<h2 className="box-title m-tb0">
										Nous somme le pont et en même temps les batisseur de
										celui-ci.<span className="bg-primary"></span>
									</h2>
									<p>
										Labor Ninja est un projet fondé en 2019 qui à pour but de
										facilité au maximum les recherches de professionnel sur le
										web qui prennent souvent beaucoup de temps ou qui sont moin
										accessible pour certaine personne moin technologue.
									</p>
									<p>
										Nos équipes sont animés par le soucis de la proximité avec
										sa clientèle par un contact humain tout en exploitant les
										technologie informatique de pointe pour facilité et
										améliorer l'éxécution de notre mission. <br />
										Grâce à nos bases de données grandisante il devien
										extrêmement facile de trouver dans des délais extêmement
										court le professionnel donc vous avez besoin et surtout le
										plus qualifié pour le type de travaux souhaité. <br />
										Il en va de même pour les travailleurs qui se vois obtenir
										des contrat plus spécifique répondant avec exactitude à
										leurs expertises.
									</p>

									<p>
										Labor Ninja à pour mission de facilité et aider au maximum
										les deux parties. C'est à dire que toutes les dispositions
										technologiques et humaines sont prise dans le but d'augmenté
										la qualité à tout les niveaux grâce notament à des suivie
										après les contrats terminé pour évaluer de façon optimal le
										bassin de Ninja à notre dispositions. Les critère sont
										varier et strict assurant ainsi des travaux de qualité
										exceptionnel. Nous ne fesont pas uniquement l'intermédiaire
										mais assurons un maintien de la qualité par tout les moyens
										possibles.
									</p>
								</div>
							</div>
							<div className="container"></div>
						</div>
						{/* <!-- Our Services --> */}
						{/* <!-- Why Chose Us --> */}
						<SectionCounter />
						{/* <!-- Why Chose Us End --> */}

						{/* <!-- Testimonials --> */}
						<div
							className="section-full content-inner "
							style={{
								backgroundImage: 'url(' + bgmap + ')',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
							}}
						>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="box-title m-tb0">
										Our Testimonials<span className="bg-primary"></span>
									</h2>
									<p>
										{' '}
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry's
										standard dummy text ever since the{' '}
									</p>
								</div>
							</div>
						</div>
						{/* <!-- Testimonials END --> */}
						{/* <!-- Get in touch --> */}
						<div
							className="section-full overlay-primary-dark bg-img-fix"
							style={{ backgroundImage: 'url(' + bg1 + ')' }}
						>
							<FormStyle />
						</div>
						{/* <!-- Get in touch --> */}
					</div>
					{/* <!-- contact area END --> */}
				</div>

				<Footer />
			</>
		)
	}
}
export default Service
