import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import bnr1 from '../../../images/banner/bnr1.jpg'

const Error404 = () => {
	let resizeMargin = () => {
		var bgImag = 'background-image: url(' + bnr1 + ');'
		var screenHeight = window.innerHeight
		document
			.getElementsByClassName('full-height')[0]
			.setAttribute('style', bgImag + 'height:' + screenHeight + 'px;')
	}

	useEffect(() => {
		resizeMargin()
		window.addEventListener('resize', resizeMargin)
	}, [])
	return (
		<>
			<div
				className="dlab-bnr-inr full-height overlay-black-dark"
				style={{ backgroundImage: 'url(' + bnr1 + ')' }}
			>
				<div className="container">
					<div className="dlab-bnr-inr-entry align-m">
						<div className="row max-w700 dz_error-404 text-white m-auto">
							<div className="col-lg-4 m-tb30">
								<div
									className="dz_error"
									style={{ backgroundColor: '#6cc000' }}
								>
									404
								</div>
							</div>
							<div className="col-lg-8 m-b30">
								<h2 className="error-head">La voix du Ninja est perdu</h2>
								<p className="font-16">
									Par delà les montagnes peuplé de ceriser en fleuraisons le
									silence règne sur le temps et l'espace ne laissant place qu'à
									cette voix intérieur faibliassant à chaques appel.
								</p>
								<p className="font-16">
									Revenez sur vos pas Ninja! Vous êtes hors du sentier de la
									vertu.
								</p>
								<Link to={'/'} className="site-button">
									Revenir à l'acceuil
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Error404
