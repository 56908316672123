import React, { Component } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ThemeButton from './Element/ThemeButton/ThemeButton'

import Homepage from './Pages/Index7'
import Index2 from './Pages/Index2'
import Index3 from './Pages/Index3'
import Index4 from './Pages/Index4'
import Index5 from './Pages/Index5'
import Index6 from './Pages/Index6'
import Index7 from './Pages/Index7'
import Aboutus1 from './Pages/Aboutus/Aboutus1'
import Aboutus2 from './Pages/Aboutus/Aboutus2'
import Error403 from './Pages/Error/Error403'
import Error404 from './Pages/Error/Error404'
import Error405 from './Pages/Error/Error405'
import ComingSoon1 from './Pages/ComingSoon/ComingSoon1'
import UnderMaintenance from './Pages/UnderMaintenance'
import Expertise from './Pages/Expertise'
import ProjectManagement from './Pages/ProjectManagement'
import OurProjects from './Pages/OurProjects'
import ProjectDetails from './Pages/ProjectDetails'
import Team from './Pages/Team'
import Faqs from './Pages/Faqs'
import Login from './Pages/Login'
import Register from './Pages/Register'
import Contact from './Pages/Contact'
import Service from './Pages/Service/Service'
import ServicesDetails from './Pages/Service/ServicesDetails'
import BlogStandard from './Pages/BlogStandard/BlogStandard'
import BlogStandardLeftSidebar from './Pages/BlogStandard/BlogStandardLeftSidebar'
import BlogStandardRightSidebar from './Pages/BlogStandard/BlogStandardRightSidebar'
import BlogClassicGrid from './Pages/BlogClassic/BlogClassicGrid'
import BlogClassicLeftSidebar from './Pages/BlogClassic/BlogClassicLeftSidebar'
import BlogClassicRightSidebar from './Pages/BlogClassic/BlogClassicRightSidebar'
import BlogListLeftSidebar from './Pages/BlogList/BlogListLeftSidebar'
import BlogListRightSidebar from './Pages/BlogList/BlogListRightSidebar'
import BlogDetails from './Pages/BlogDetail/BlogDetails'
import ProtfolioFullWidth from './Pages/Protfolio/ProtfolioFullWidth'
import PortfolioGrid2 from './Pages/Protfolio/PortfolioGrid2'
import PortfolioGrid3 from './Pages/Protfolio/PortfolioGrid3'
import PortfolioGrid4 from './Pages/Protfolio/PortfolioGrid4'
import ShopColumns from './Pages/Shop/ShopColumns'
import ShopColumnsSidebar from './Pages/Shop/ShopColumnsSidebar'

import ShopCart from './Pages/Shop/ShopCart'
import ShopCheckout from './Pages/Shop/ShopCheckout'
import ShopProductDetails from './Pages/Shop/ShopProductDetails'

import ShopWishlist from './Pages/Shop/ShopWishlist'
import ShopLogin from './Pages/Shop/ShopLogin'
import ShopRegister from './Pages/Shop/ShopRegister'

import ShortIconBox from './Pages/ShortCode/ShortIconBox'
import ShortCounter from './Pages/ShortCode/ShortCounter'
import ShortPortfolio from './Pages/ShortCode/ShortPortfolio'
import ShortTabs from './Pages/ShortCode/ShortTabs'
import ShortTeam from './Pages/ShortCode/ShortTeam'
import ShortTestimonial from './Pages/ShortCode/ShortTestimonial'
import ShortForm from './Pages/ShortCode/ShortForm'
import ShortAccordions from './Pages/ShortCode/ShortAccordions'

import ScrollToTop from './Element/ScrollToTop'

class Markup extends Component {
	render() {
		return (
			<BrowserRouter basename="/">
				<div className="page-wraper">
					<Routes>
						<Route path="/" exact element={<Homepage />} />
						<Route path="/index-2" exact element={<Index2 />} />
						<Route path="/index-3" exact element={<Index3 />} />
						<Route path="/index-4" exact element={<Index4 />} />
						<Route path="/index-5" exact element={<Index5 />} />
						<Route path="/index-6" exact element={<Index6 />} />
						<Route path="/index-7" exact element={<Index7 />} />
						<Route path="/about-1" exact element={<Aboutus1 />} />
						<Route path="/about-2" exact element={<Aboutus2 />} />
						<Route path="/error-403" exact element={<Error403 />} />
						<Route path="/error-404" exact element={<Error404 />} />
						<Route path="/error-405" exact element={<Error405 />} />
						<Route path="/coming-soon-1" exact element={<ComingSoon1 />} />
						<Route
							path="/under-maintenance"
							exact
							element={<UnderMaintenance />}
						/>
						<Route path="/expertise" exact element={<Expertise />} />
						<Route
							path="/project-management"
							exact
							element={<ProjectManagement />}
						/>
						<Route path="/our-projects" exact element={<OurProjects />} />
						<Route path="/project-details" exact element={<ProjectDetails />} />
						<Route path="/team" exact element={<Team />} />
						<Route path="/faqs" exact element={<Faqs />} />
						<Route path="/login" exact element={<Login />} />
						<Route path="/register" exact element={<Register />} />
						<Route path="/contact" exact element={<Contact />} />
						<Route path="/service" exact element={<Service />} />
						<Route
							path="/services-details"
							exact
							element={<ServicesDetails />}
						/>
						<Route path="/blog-standard" exact element={<BlogStandard />} />
						<Route
							path="/blog-standard-left-sidebar"
							exact
							element={<BlogStandardLeftSidebar />}
						/>
						<Route
							path="/blog-standard-right-sidebar"
							exact
							element={<BlogStandardRightSidebar />}
						/>
						<Route
							path="/blog-classic-grid"
							exact
							element={<BlogClassicGrid />}
						/>
						<Route
							path="/blog-classic-left-sidebar"
							exact
							element={<BlogClassicLeftSidebar />}
						/>
						<Route
							path="/blog-classic-right-sidebar"
							exact
							element={<BlogClassicRightSidebar />}
						/>
						<Route
							path="/blog-list-left-sidebar"
							exact
							element={<BlogListLeftSidebar />}
						/>
						<Route
							path="/blog-list-right-sidebar"
							exact
							element={<BlogListRightSidebar />}
						/>
						<Route path="/blog-details" exact element={<BlogDetails />} />
						<Route
							path="/portfolio-full-width"
							exact
							element={<ProtfolioFullWidth />}
						/>
						<Route
							path="/portfolio-grid-2"
							exact
							element={<PortfolioGrid2 />}
						/>
						<Route
							path="/portfolio-grid-3"
							exact
							element={<PortfolioGrid3 />}
						/>
						<Route
							path="/portfolio-grid-4"
							exact
							element={<PortfolioGrid4 />}
						/>
						<Route path="/shop-columns" exact element={<ShopColumns />} />
						<Route
							path="/shop-columns-sidebar"
							exact
							element={<ShopColumnsSidebar />}
						/>

						<Route path="/shop-cart" exact element={<ShopCart />} />
						<Route path="/shop-checkout" exact element={<ShopCheckout />} />
						<Route
							path="/shop-product-details"
							exact
							element={<ShopProductDetails />}
						/>
						<Route path="/shop-wishlist" exact element={<ShopWishlist />} />
						<Route path="/shop-login" exact element={<ShopLogin />} />
						<Route path="/shop-register" exact element={<ShopRegister />} />

						<Route path="/short-icon-box" exact element={<ShortIconBox />} />
						<Route path="/short-counter" exact element={<ShortCounter />} />
						<Route path="/short-portfolio" exact element={<ShortPortfolio />} />
						<Route path="/short-tabs" exact element={<ShortTabs />} />
						<Route path="/short-team" exact element={<ShortTeam />} />
						<Route
							path="/short-testimonial"
							exact
							element={<ShortTestimonial />}
						/>
						<Route path="/short-form" exact element={<ShortForm />} />
						<Route
							path="/short-accordions"
							exact
							element={<ShortAccordions />}
						/>
					</Routes>
				</div>
				<ScrollToTop />
				<ThemeButton />
			</BrowserRouter>
		)
	}
}

export default Markup
